exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var getUrl = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL___0___ = getUrl(require("./svg/activities.svg"));
var ___CSS_LOADER_URL___1___ = getUrl(require("./svg/animals_nature.svg"));
var ___CSS_LOADER_URL___2___ = getUrl(require("./svg/flags.svg"));
var ___CSS_LOADER_URL___3___ = getUrl(require("./svg/food_drink.svg"));
var ___CSS_LOADER_URL___4___ = getUrl(require("./svg/objects.svg"));
var ___CSS_LOADER_URL___5___ = getUrl(require("./svg/smileys_people.svg"));
var ___CSS_LOADER_URL___6___ = getUrl(require("./svg/symbols.svg"));
var ___CSS_LOADER_URL___7___ = getUrl(require("./svg/travel_places.svg"));
// Module
exports.push([module.id, ".emoji-picker-react .emoji-categories button {\n    height: 40px;\n    width: 20px;\n    padding: 5px 0;\n    background-repeat: no-repeat;\n    background-size: 20px;\n    background-position: 50% 50%;\n    cursor: pointer;\n    opacity: .5;\n    transition: opacity .1s;\n}\n\n.emoji-picker-react .emoji-categories button.icn-activities     { background-image: url(" + ___CSS_LOADER_URL___0___ + "); }\n.emoji-picker-react .emoji-categories button.icn-animals_nature { background-image: url(" + ___CSS_LOADER_URL___1___ + "); }\n.emoji-picker-react .emoji-categories button.icn-flags          { background-image: url(" + ___CSS_LOADER_URL___2___ + "); }\n.emoji-picker-react .emoji-categories button.icn-food_drink     { background-image: url(" + ___CSS_LOADER_URL___3___ + "); }\n.emoji-picker-react .emoji-categories button.icn-objects        { background-image: url(" + ___CSS_LOADER_URL___4___ + "); }\n.emoji-picker-react .emoji-categories button.icn-smileys_people { background-image: url(" + ___CSS_LOADER_URL___5___ + "); }\n.emoji-picker-react .emoji-categories button.icn-symbols        { background-image: url(" + ___CSS_LOADER_URL___6___ + "); }\n.emoji-picker-react .emoji-categories button.icn-travel_places  { background-image: url(" + ___CSS_LOADER_URL___7___ + "); }\n\n\n.emoji-picker-react .emoji-categories {\n    padding: 0 15px;\n    display: flex;\n    justify-content: space-between;\n    box-sizing: border-box;\n}\n\n.emoji-picker-react .emoji-categories.inactive button,\n.emoji-picker-react .emoji-categories.inactive button.active,\n.emoji-picker-react .emoji-categories.inactive button:hover {\n    opacity: .4;\n    cursor: default;\n}\n\n.emoji-picker-react .emoji-categories button.active {\n    opacity: 1;\n}\n\n.emoji-picker-react .emoji-categories button:hover {\n    opacity: .7;\n}\n", ""]);
